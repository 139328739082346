import './modules/jquery.global-dollar.js'
import '@vendor/ownbit/wp-module-responsivenavigation/js/jquery.ResponsiveNavigation.js'
import './modules/jquery.menu.js'

$(function () {
  $('header.main').Menu({
    toggleClass: 'toggled',
    toggleContainer: '.toggle-container',
    toggleButton: '.toggle-button',
    closeButton: '.close-button'
  })

  /*******************************
   * resizeEnd Event (https://stackoverflow.com/a/12692647/12404191)
   * Example usage:
   *
   * $(window).bind('resizeEnd', function() {
   * 		//do something, window hasn't changed size in 500ms
   * });
   ******************************/

  $(window).resize(function () {
    if (this.resizeEndTimeout) clearTimeout(this.resizeEndTimeout)
    this.resizeEndTimeout = setTimeout(function () {
      $(window).trigger('resizeEnd')
    }, 500)
  })

  /*******************************
   * Sticky Header
   ******************************/

  var header = document.querySelector('header.main')
  var treshold = 30
  var compactClass = 'is-small'
  var scrollAnimFrame = null
  var headerInitialSize = header.getBoundingClientRect().height

  function checkScroll () {
    if(scrollAnimFrame) {
      cancelAnimationFrame(scrollAnimFrame)
    }
    scrollAnimFrame = requestAnimationFrame(function() {
      var scrollY = window.pageYOffset
      if (scrollY > treshold) {
        header.classList.add(compactClass)
      } else if (scrollY < headerInitialSize + treshold) {
        header.classList.remove(compactClass)
      }
    })
  }

  checkScroll()
  header.classList.add('with-animation')

  document.addEventListener('scroll', function (e) {
    checkScroll()
  }, {
    capture: true,
    passive: true
  })

  /*******************************
   *  --vh CSS variable
   *  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
   ******************************/

  function calculateVh() {
    var vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', vh + 'px')
  }

  calculateVh()

  $(window).on('resizeEnd', function () {
    calculateVh()
  })

  /*******************************
   * Hover Submenu on Touch Devices
   *
   * First click on menu item with children opens submenu
   * and the second click on the item itself would follow its href.
   ******************************/

  var $nav = $('nav.main')
  var $topLevelSubmenuLinks = $nav.find('> ul > li.menu-item-has-children > a')
  var $menuItemsWithChildren = $nav.find('li.menu-item-has-children')

  $topLevelSubmenuLinks.on('touchstart', function (e) {
    if (!$(this).parent().is(':focus')) {
      e.preventDefault()
    }
  })

  $menuItemsWithChildren
    .attr('tabindex', '-1')
    .on('touchstart', function (e) {
      $(this).focus()
      $menuItemsWithChildren.removeClass('submenu-active')
      $(this).toggleClass('submenu-active')
      e.stopPropagation()
    })
    .on('blur', function (e) {
      if (!e.target.contains(e.relatedTarget)) {
        $(this).removeClass('submenu-active')
      }
    })
})
